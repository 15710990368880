import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PageDefaultHeader, GroupsDetailTheatreTable } from 'qw-ui-lib-v2'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import bindDispatch from '../../common/util/redux'

const GroupDetailsAdmin = ({
  groups: {
    loading,
    groupDetail: { groupName, theatres }
  },
  actions: { fetchTheatresInGroupForAdmin }
}) => {
  const { groupId } = useParams()
  useEffect(() => {
    fetchTheatresInGroupForAdmin(groupId)
  }, [fetchTheatresInGroupForAdmin, groupId])

  return (
    <>
      <PageDefaultHeader content={groupName} />
      <div className="page-container">
        <GroupsDetailTheatreTable
          loading={loading}
          theatres={_.isEmpty(theatres) ? [] : theatres}
        />
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    groups: state.groups
  }
}

GroupDetailsAdmin.propTypes = {
  groups: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
}

export default connect(mapStateToProps, bindDispatch)(GroupDetailsAdmin)
