import update from 'react-addons-update'
import _ from 'lodash'
import { I18n } from 'react-redux-i18n'
import axios from 'axios'
import { showAppBusy, displayError } from './app'
import qwAppliance from '../common/client/qwAppliance'
import qwInbox from '../common/client/qwInbox'
import { frameTheatreAddress, frameLocation ,capitalizeFirstletter} from '../common/util/helper'

const { CancelToken } = axios
const source = CancelToken.source()

const initialFilterState = {
  searchText: '',
  selectedList: [],
  searchList: [],
  searchInProgress: false
}
const initialListFilterState = {
  selectedList: [],
  optionsList: []
}
const initialState = {
  appliances: {},
  appliancesDashboard: {
    appliances: [],
    offset: 0,
    ps: 10,
    totalCount: 0,
    page: 1,
    isLoading: false
  },
  currentAppliance: {},
  code: '',
  appliancesFilters: {
    chain: { ...initialFilterState },
    ipAddress: { ...initialFilterState },
    theatre: { ...initialFilterState },
    location: { ...initialFilterState },
    status: { ...initialListFilterState },
    serialNumber: { ...initialFilterState },
    osVersion: { ...initialFilterState }
  }
}

const UPDATE_APPLIANCE = 'appliance/UPDATE_APPLIANCE'
const RESET_APPLIANCE = 'appliance/RESET_APPLIANCE'
const RECEIVE_APPLIANCES_STATUS = 'APPLIANCE/RECEIVE_APPLIANCES_STATUS'
const RECEIVE_FIRST_BATCH_OF_DASHBOARD_APPLIANCES =
  'APPLIANCE/RECEIVE_FIRST_BATCH_OF_DASHBOARD_APPLIANCES'
const GET_TOTAL_COUNT = 'appliance/GET_TOTAL_COUNT'
const RECEIVE_MORE_DASHBOARD_APPLIANCES = 'APPLIANCE/RECEIVE_MORE_DASHBOARD_APPLIANCES'
const RESET_DASHBOARD_APPLIANCE = 'APPLIANCE/RESET_DASHBOARD_APPLIANCE'
const UPDATE_CURRENT_APPLIANCE = 'appliance/UPDATE_CURRENT_APPLIANCE'
const RESET_CURRENT_APPLIANCE = 'appliance/RESET_CURRENT_APPLIANCE'
const UPDATE_APPLIANCE_THEATRE = 'appliance/UPDATE_APPLIANCE_THEATRE'
const SET_LOADING = 'appliance/SET_LOADING'
const SET_CODE_APPLIANCE = 'appliance/SET_CODE'

// Appliance Search and Filter related Actions
const RECEIVED_SEARCH_SUGGESTIONS = 'appliance/RECEIVED_SEARCH_SUGGESTIONS'
const TOGGLE_SEARCH_IN_PROGESSS = 'appliances/TOGGLE_SEARCH_IN_PROGESSS'
const UPDATE_SELECTED_FILTER_DATA = 'appliances/UPDATE_SELECTED_FILTER_DATA'
const RESET_FILTER_DATA = 'appliances/RESET_FILTER_DATA'
const RESET_LIST_FILTER_DATA = 'appliances/RESET_LIST_FILTER_DATA'
const UPDATE_FILTER_SEARCH_TEXT = 'appliances/UPDATE_FILTER_SEARCH_TEXT'
const RESET_ALL_FILTER_DATA = 'appliances/RESET_ALL_FILTER_DATA'
const RECEIVED_APPLIANCE_STATUS_LIST = 'appliances/RECEIVED_APPLIANCE_STATUS_LIST'

const resetAllFilterData = (payload) => ({ type: RESET_ALL_FILTER_DATA,payload })

const resetFilterData = filterType => {
  if (filterType === 'status') {
    return { type: RESET_LIST_FILTER_DATA, meta: filterType }
  }
  return { type: RESET_FILTER_DATA, meta: filterType }
}

const receivedStatusList = payload => ({ type: RECEIVED_APPLIANCE_STATUS_LIST, payload })

const updateAppliancesWS = payload => ({ type: UPDATE_APPLIANCE, payload })

const updateApplianceTheatre = (payload, indexap) => ({
  type: UPDATE_APPLIANCE_THEATRE,
  payload,
  meta: { indexap }
})

const receivesApplianceStatus = (payload, index) => ({
  type: RECEIVE_APPLIANCES_STATUS,
  payload,
  meta: { index }
})

const receiveFirstBatchOfDashboardAppliances = (payload, meta) => ({
  type: RECEIVE_FIRST_BATCH_OF_DASHBOARD_APPLIANCES,
  payload,
  meta
})

const receiveMoreDashboardAppliances = (payload, meta) => ({
  type: RECEIVE_MORE_DASHBOARD_APPLIANCES,
  payload,
  meta
})

const setLoading = payload => ({ type: SET_LOADING, payload })

const resetDashboardAppliances = () => ({ type: RESET_DASHBOARD_APPLIANCE })

const resetAppliancesWS = () => ({ type: RESET_APPLIANCE })

const getTotalCount = payload => ({ type: GET_TOTAL_COUNT, payload })

const updateCurrentAppliance = payload => ({ type: UPDATE_CURRENT_APPLIANCE, payload })

const resetCurrentAppliance = () => ({ type: RESET_CURRENT_APPLIANCE })

const setApplianceCode = code => ({
  type: SET_CODE_APPLIANCE,
  payload: code
})

const updateAppliance = appliance => {
  return dispatch => {
    const applianceObject = {}
    applianceObject[appliance.id] = { appliance }
    dispatch(updateAppliancesWS(applianceObject))
  }
}

const updateAppliancesStatus = newAppliances => {
  return (dispatch, getState) => {
    const {
      appliancesDashboard: { appliances }
    } = getState().appliances
    newAppliances.forEach(appliance => {
      const updatedApplianceIndex = _.isEmpty(appliance)
        ? 0
        : appliances.findIndex(appl => appl.id === appliance.applianceId)
      if (updatedApplianceIndex > -1) {
        dispatch(receivesApplianceStatus(appliance, updatedApplianceIndex))
        dispatch(updateCurrentAppliance(appliance))
      }
    })
  }
}

const fetchTotalCount = reqBody => {
  const data = {
    type: 'theatre',
    filters: { ...reqBody }
  }
  return dispatch => {
    const url = 'v1/appliances/theatre-appliances/count'
    qwAppliance
      .post(url, { ...data })
      .then(res => {
        const { count } = res.data
        dispatch(getTotalCount(count))
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.fetchCount')))
        }
      })
  }
}

const getTheatreAppliance = (appliance, idxap) => {
  return dispatch => {
    dispatch(showAppBusy(true))
    let id = ''
    appliance.facilities.forEach(facility => {
      if (facility.id === null) {
        return
      }
      id = `${id}id=${facility.id}&`
    })
    id = id.substring(0, id.length - 1)

    const url = `/theatres?${id}`
    return qwInbox
      .get(url, { cancelToken: source.token })
      .then(res => {
        dispatch(showAppBusy(false))
        dispatch(updateApplianceTheatre(res.data, idxap))
        dispatch(setLoading(false))
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.fetchTheatres')))
          dispatch(showAppBusy(false))
        }
      })
  }
}
const frameRequestOptions = filters => {
  let body = { ...filters }
  Object.keys(body).forEach(key => {
    if (key === 'location') {
      body[key].selectedList.map(el => {
        if (body[el.type]) return (body[el.type] = [...body[el.type], el.id])
        body = { ...body, [el.type]: [] }
        return (body[el.type] = [...body[el.type], el.id])
      })
    } else body[key] = body[key].selectedList.map(el => el.id) || []
  })
  delete body.location
  return body
}

const loadAppliances = (offset, ps, page) => {
  return (dispatch, getState) => {
    const reqBody = frameRequestOptions(getState().appliances.appliancesFilters)
    const data = {
      type: 'theatre',
      filters: { ...reqBody },
      offset,
      ps
    }
    dispatch(showAppBusy(true))
    dispatch(setLoading(true))
    const url = '/v1/appliances/theatre-appliances'

    if (offset === 0) {
      dispatch(showAppBusy(true))
      dispatch(resetDashboardAppliances())
    }

    return qwAppliance
      .post(url, { ...data })
      .then(res => {
        const { appliances, code } = res.data
        const meta = {
          offset,
          page
        }
        dispatch(showAppBusy(false))
        if (code) {
          dispatch(receiveFirstBatchOfDashboardAppliances([], meta))
          dispatch(setApplianceCode(code))
        } else if (offset === 0) {
          dispatch(receiveFirstBatchOfDashboardAppliances(appliances, meta))
        } else {
          dispatch(receiveMoreDashboardAppliances(appliances, meta))
        }
        dispatch(setLoading(false))
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.applianceInfo')))
        }
        dispatch(showAppBusy(false))
        dispatch(setLoading(false))
      })
  }
}

const loadMoreAppliances = newPage => {
  return (dispatch, getState) => {
    let {
      appliancesDashboard: { offset }
    } = getState().appliances

    const {
      appliancesDashboard: { ps, totalCount }
    } = getState().appliances

    offset = (newPage - 1) * ps
    if (offset > totalCount) return

    dispatch(loadAppliances(offset, ps, newPage))
  }
}

const loadFirstBatchOfAppliances = () => {
  return dispatch => {
    const {
      appliancesDashboard: { offset, ps, page }
    } = initialState

    dispatch(loadAppliances(offset, ps, page))
  }
}

const fetchApplianceStatusList = () => {
  return (dispatch, getState) => {
    const currentList = getState().appliances.appliancesFilters.status.optionsList
    const url = '/v1/appliances/status'
    const config = {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_APPLIANCE_STATIC_TOKEN}`
      }
    }
    qwAppliance
      .get(url, config)
      .then(result => {
        const optionsList = Object.keys(result.data).map(key => ({
          id: result.data[key],
          name: key.charAt(0).toUpperCase() + key.slice(1),
          displayName: key.charAt(0).toUpperCase() + key.slice(1)
        }))
        dispatch(receivedStatusList([...currentList, ...optionsList]))
      })
      .catch(error => {
        console.log('error', error)
      })
  }
}

const toggleSearchInProgess = (payload, meta) => ({
  type: TOGGLE_SEARCH_IN_PROGESSS,
  payload,
  meta
})

const receivedSearchSuggestions = (payload, meta) => ({
  type: RECEIVED_SEARCH_SUGGESTIONS,
  payload,
  meta
})

const updateSelectedFilters = (payload, meta) => ({
  type: UPDATE_SELECTED_FILTER_DATA,
  payload,
  meta
})

const getFiltersSearch = (query, filterType) => {
  return dispatch => {
    dispatch(receivedSearchSuggestions([], filterType))
    dispatch(toggleSearchInProgess(true, filterType))
    const url = `/v1/appliances/theatre-appliances/search?q=${query}&type=${filterType}`
    qwAppliance
      .get(url)
      .then(result => {
        dispatch(toggleSearchInProgess(false, filterType))
        if (result.data.results) {
          if (filterType === 'theatre') {
            result.data.results.forEach(theatre => {
              const address = frameTheatreAddress(theatre.details)
              theatre.displayName = `${theatre.displayName}${address ? ` • ${address}` : ``}`
            })
          } else if (filterType === 'location') {
            result.data.results.forEach(location => {
              const formattedLocation = frameLocation(location)
              location.displayName = formattedLocation
            })
          }
          dispatch(receivedSearchSuggestions(result.data.results, filterType))
        }
      })
      .catch(error => {
        dispatch(toggleSearchInProgess(false, filterType))
        dispatch(toggleSearchInProgess(false, filterType))
        dispatch(displayError(capitalizeFirstletter(error.response.data.message)))
      })
  }
}

const setSearchText = (payload, meta) => ({ type: UPDATE_FILTER_SEARCH_TEXT, payload, meta })

const removeAllFilters = () => {
  return (dispatch,getState) => {
    let status = {...getState().appliances.appliancesFilters.status}
    status.selectedList = []
    let applianceFilters = {
      chain: { ...initialFilterState },
      ipAddress: { ...initialFilterState },
      theatre: { ...initialFilterState },
      location: { ...initialFilterState },
      status:status,
      serialNumber: { ...initialFilterState },
      osVersion: { ...initialFilterState }
    }
    dispatch(resetAllFilterData(applianceFilters))
    dispatch(updateApplianceDataOnFilterChange())
  }
}
const resetFilterType = filterType => {
  return dispatch => {
    dispatch(resetFilterData(filterType))
    dispatch(updateApplianceDataOnFilterChange())
  }
}
const updateApplianceDataOnFilterChange = () => {
  return (dispatch, getState) => {
    const reqBody = frameRequestOptions(getState().appliances.appliancesFilters)
    dispatch(fetchTotalCount(reqBody))
    dispatch(loadFirstBatchOfAppliances())
  }
}
const addItemToFilter = (item, filterType) => {
  return (dispatch, getState) => {
    const currentList = getState().appliances.appliancesFilters[filterType].selectedList
    dispatch(updateSelectedFilters(currentList.concat(item), filterType))
    dispatch(setSearchText('', filterType))
    dispatch(updateApplianceDataOnFilterChange())
  }
}

const handleListFilter = (item, filterType) => {
  return (dispatch, getState) => {
    const currentList = getState().appliances.appliancesFilters[filterType].selectedList
    if (currentList.filter(listItem => listItem.id === item.id).length)
      dispatch(
        updateSelectedFilters(
          currentList.filter(listItem => listItem.id !== item.id),
          filterType
        )
      )
    else dispatch(updateSelectedFilters(currentList.concat(item), filterType))

    dispatch(updateApplianceDataOnFilterChange())
  }
}

const removeItemFromFilter = (item, filterType) => {
  return (dispatch, getState) => {
    const currentList = getState().appliances.appliancesFilters[filterType].selectedList
    dispatch(
      updateSelectedFilters(
        currentList.filter(listItem => listItem.id !== item.id),
        filterType
      )
    )
    dispatch(updateApplianceDataOnFilterChange())
  }
}

export default function reducer(state = initialState, action) {
  const { type, payload, meta } = action
  switch (type) {
    case UPDATE_APPLIANCE:
      return update(state, {
        appliancesDashboard: { appliances: { $merge: payload } }
      })
    case UPDATE_APPLIANCE_THEATRE:
      return update(state, {
        appliancesDashboard: {
          appliances: {
            [meta.indexap]: { facilities: { $merge: payload } }
          }
        }
      })
    case SET_LOADING:
      return update(state, {
        appliancesDashboard: {
          isLoading: { $set: payload }
        }
      })
    case RESET_APPLIANCE:
      return update(state, {
        $set: initialState
      })
    case RECEIVE_FIRST_BATCH_OF_DASHBOARD_APPLIANCES:
      return update(state, {
        appliancesDashboard: {
          appliances: { $set: payload },
          offset: { $set: meta.offset },
          searchStatus: { $set: meta.searchStatus },
          page: { $set: meta.page }
        }
      })
    case RECEIVE_MORE_DASHBOARD_APPLIANCES:
      return update(state, {
        appliancesDashboard: {
          appliances: { $set: payload },
          offset: { $set: meta.offset },
          searchStatus: { $set: meta.searchStatus },
          page: { $set: meta.page }
        }
      })
    case RECEIVE_APPLIANCES_STATUS:
      return update(state, {
        appliancesDashboard: { appliances: { [meta.index]: { $merge: payload } } }
      })
    case RESET_DASHBOARD_APPLIANCE:
      return update(state, {
        appliancesDashboard: { appliances: { $set: [] } }
      })
    case UPDATE_CURRENT_APPLIANCE:
      return update(state, {
        currentAppliance: { $merge: payload }
      })
    case RESET_CURRENT_APPLIANCE:
      return update(state, {
        currentAppliance: { $set: {} }
      })
    case GET_TOTAL_COUNT:
      return update(state, {
        appliancesDashboard: {
          totalCount: { $set: payload }
        }
      })
    case SET_CODE_APPLIANCE:
      return update(state, { code: { $set: action.payload } })
    case RECEIVED_APPLIANCE_STATUS_LIST:
      return update(state, {
        appliancesFilters: {
          status: { optionsList: { $set: payload } }
        }
      })

    case UPDATE_FILTER_SEARCH_TEXT:
      return update(state, { appliancesFilters: { [meta]: { searchText: { $set: payload } } } })
    case RECEIVED_SEARCH_SUGGESTIONS:
      return update(state, { appliancesFilters: { [meta]: { searchList: { $set: payload } } } })
    case TOGGLE_SEARCH_IN_PROGESSS:
      return update(state, {
        appliancesFilters: { [meta]: { searchInProgress: { $set: payload } } }
      })
    case UPDATE_SELECTED_FILTER_DATA:
      return update(state, { appliancesFilters: { [meta]: { selectedList: { $set: payload } } } })
    case RESET_FILTER_DATA:
      return update(state, {
        appliancesFilters: {
          [meta]: {
            $set: { ...initialFilterState }
          }
        }
      })
    case RESET_LIST_FILTER_DATA:
      return update(state, {
        appliancesFilters: {
          [meta]: {
            selectedList: { $set: [] }
          }
        }
      })
    case RESET_ALL_FILTER_DATA:
      return update(state, {
        appliancesFilters: {
          $set: { ...payload }
        }
      })
    default:
      return state
  }
}

export {
  receivesApplianceStatus,
  receiveFirstBatchOfDashboardAppliances,
  receiveMoreDashboardAppliances,
  setLoading,
  resetDashboardAppliances,
  resetAppliancesWS,
  getTotalCount,
  updateCurrentAppliance,
  resetCurrentAppliance,
  setApplianceCode,
  updateAppliance,
  updateAppliancesStatus,
  fetchTotalCount,
  getTheatreAppliance,
  loadAppliances,
  loadMoreAppliances,
  loadFirstBatchOfAppliances,
  updateApplianceTheatre,
  getFiltersSearch,
  setSearchText,
  addItemToFilter,
  removeItemFromFilter,
  resetAllFilterData,
  resetFilterData,
  handleListFilter,
  fetchApplianceStatusList,
  removeAllFilters,
  resetFilterType,
  receivedSearchSuggestions
}
