import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter } from 'react-router-dom'
import './App.less'
import { CINEMA_UI_URL } from './common/config'
import { getAccessToken } from './common/util/helper'
import DefaultLayout from './layouts/defaultLayout'
import store from './store'
import './style/antd-override.less'
import './style/qw-css.less'
import './style/qw-theme.less'

import { Enviroinment } from './common/enums'

const App = () => {
  useEffect(() => {
    if (!getAccessToken() && process.env.REACT_APP_ENV !== Enviroinment.test) {
      window.location = CINEMA_UI_URL
    }
  }, [])

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route path="/" component={DefaultLayout} />
      </BrowserRouter>
    </Provider>
  )
}

export default App
