export default {
  general: {
    to: 'to',
    none: 'None'
  },
  navMenu: {
    movies: 'movies',
    manage: 'manage',
    logout: 'logout'
  },
  pages: {
    movies: 'Universal Inbox'
  },
  compositions: {
    searchPlaceholder: 'Search for Compositions',
    filter: 'filter',
    filterInfoTitle: 'Filter - Movies',
    sortBy: 'Sorted by',
    expandAll: 'Expand All',
    collapseAll: 'Collapse All',
    view: 'View',
    viewFields: {
      all: 'all',
      sample: 'sample'
    },
    sortFields: {
      urgency: 'Relevance',
      other: 'other'
    },
    dcpStatusTitle: 'DCP Status',
    kdmStatusTitle: 'KDM Status',
    dcpStatus: {
      downloaded: 'Downloaded',
      pending: 'Pending',
      cancelled: 'Cancelled',
      dispatched: 'Shipped',
      pending_cancellation: 'Pending Cancellation',
      queued: 'Queued',
      failed: 'Failed',
      error: 'Failed',
      succeeded: 'Succeeded',
      executing: 'Downloading...',
      scheduled: 'Scheduled for Download',
      downloading: 'Downloading...',
      expired: 'Failed',
      intransit: 'In Transit',
      outfordelivery: 'Out For Delivery',
      attemptfail: 'Delivery AttemptFail',
      delivered: 'Delivered',
      exception: 'Delivery Exception',
      unarchiving: 'Unarchiving',
      downloading_unarchiving: 'Downloading - Unarchiving',
      pending_fulfilment_unarchiving: 'Pending Fulfilment - Unarchiving',
      archived: 'Archived',
      downloading_archived: 'Downloading - Archived',
      pending_fulfilment_archived: 'Pending Fulfilment - Archived',
      stalled: 'Stalled',
      downloading_stalled: 'Downloading - Stalled',
      pending_fulfilment_stalled: 'Pending Fulfilment - Stalled',
      in_progress: 'In Progress',
      completed: 'Completed'
    },
    kdmStatus: {
      emailed: 'Emailed',
      generated: 'Available',
      failed: 'Failed',
      inprogress: 'In Progress',
      success: 'Available',
      unavailable: 'Unavailable'
    },
    viewCreditOffset: 'Credits: %{value}',
    noCreditOffset: 'No Credit Offset',
    theatres: 'Theatres',
    screens: 'Screens',
    theatre: 'Theatre',
    screen: 'Screen',
    multipleValidities: 'Multiple Validities',
    downloadKDMs: 'Download KDMs',
    creditOffset: 'Credit Offset',
    kdmDetails: 'KDM Details',
    assetDetails: 'Asset Details',
    name: 'Composition Name',
    status: 'Status',
    downloadAll: 'Download All',
    validity: 'Validity',
    multipleEmails: 'Multiple Emails',
    download: 'Download',
    markers: {
      ffec: 'FFEC',
      ffmc: 'FFMC'
    },
    cplMarkers: 'CPL Markers',
    creditsOffset: 'Credits Offset',
    standByServer: 'Standby Server',
    no_compositions: 'No Compositions',
    code: {
      INB01: {
        no_group_assigned: 'You have currently not been assigned any theatres/groups on Qube Wire.',
        contact_admin:
          'Contact your company admin to start managing content & keys for your theatres!'
      }
    },
    attributes: {
      language: 'Language',
      subtitleLanguage: 'Subtitle Language',
      mainCaption: 'Main Caption',
      ratings: 'Ratings',
      type: 'Type',
      dciStandard: 'DCI Standard',
      closedSubtitles: 'Closed Subtitles',
      frameRate: 'Frame Rate',
      audioFormats: 'Audio Formats',
      resolution: 'Resolution',
      closedCaptions: 'Closed Captions',
      stereoType: 'Stereo Type',
      aspectRatio: 'Aspect Ratio'
    },
    kdmDeliveryEmail: 'KDM Delivery Email',
    emailKDMS: 'Email KDMs',
    enterEmailAddress: 'Enter Email Addresses',
    email: 'Email',
    sendKDMEmailError: {
      empty: 'Atleast one email is required',
      invalidEmail: 'Invalid Email',
      send: 'Error in sending KDM Email'
    },
    sendKDMEmailSuccess: "KDM Email's sent",
    kdm: 'KDM',
    dcp: 'DCP',
    dcpDownloadCompleted: 'Completed',
    trackingInfo: 'Tracking Info'
  },
  filterPanel: {
    theatres: {
      title: 'Theatres',
      type: 'Theatres',
      placeholder: 'Search for theatres',
      dropDownPlaceholder: 'Search for theatres'
    },
    kdmValidity: {
      title: 'KDM Validity',
      type: 'KDM Validity',
      kdmValidityOptions: {
        validNow: 'Valid Now',
        future: 'Valid in Future',
        expiring: 'Expiring (in %{days} days)',
        expired: 'Expired'
      }
    }
  },
  filterLabel: {
    theatre: 'Theatre'
  },
  theatre: {
    screen: 'Screen',
    theatres: 'theatres',
    chains: 'chains',
    no_theatres: 'No Theatres available',
    theatre: 'Theatre',
    filterByTheatres: 'Filter by Theatres',
    searchPlaceholder: 'Search for theatres you manage'
  },
  errors: {
    fetchCompositions: 'Error in loading compositions',
    fetchTheatres: 'Error in loading theatres',
    fetchUser: 'Error in loading user information',
    downloadKDMs: 'Error in KDM download',
    NoDownloadKDMs: 'No KDMs to download',
    fetchCount: 'Error in loading total no of WireTAPs',
    applianceInfo: 'Error in loading WireTAPs',
    fetchAllUsers: 'Error in loading users list',
    createGroup: 'Error while creating group',
    fetchGroups: 'Error in loading groups list',
    fetchFilterSettings: 'Error while fetching filter settings'
  },
  shipmentStatuses: {
    Pending: 'Pending',
    InfoReceived: 'In Transit',
    InTransit: 'In Transit',
    OutForDelivery: 'Out For Delivery',
    AttemptFail: 'Delivery Attempt Failed',
    Delivered: 'Delivered',
    Exception: 'Delivery Exception',
    Expired: 'Expired'
  },
  unarchival_tooltip: {
    unarchival_may_take: 'Unarchival may take',
    hour: 'Hour',
    minute: 'Minute',
    from_time_of_booking: 'from the time of booking.'
  },
  users: {
    headerTitle: 'Users',
    headerButtons: {
      editRoles: 'Edit Roles',
      inviteUsers: 'Invite Users',
      viewGroups: 'View Groups'
    }
  },
  groups: {
    groupsList: {
      headerTitle: 'Groups',
      headerButtons: {
        createGroup: 'Create Group'
      }
    },
    groupDetail: {
      headerButtons: {
        addUser: 'Add User',
        editGroup: 'Edit Group'
      }
    },
    createGroup: {
      headerTitle: 'Create Group',
      headerButtons: {
        cancel: 'Cancel',
        save: 'Save'
      },
      groupName: 'Group Name',
      groupNamePlaceholder: 'Enter Group Name',
      groupCreated: 'Group created'
    },
    editGroup: 'Edit group',
    groupUpdated: 'Group Updated'
  }
}
