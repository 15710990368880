import axios from 'axios'
import Qs from 'qs'
import { CINEMA_UI_URL } from '../config'

const cinemas = axios.create({
  baseURL: CINEMA_UI_URL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default cinemas
