import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n'
import { applyMiddleware, createStore } from 'redux'

import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import reducer from './actions'
import locale from './locale'

const middleware = [promise, thunk]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)))
syncTranslationWithStore(store)
store.dispatch(loadTranslations(locale))
store.dispatch(setLocale('en')) // to change locale

export default store
