import axios from 'axios'
import Qs from 'qs'
import { APPLIANCE_BASE_URL } from '../config'
import { getAccessToken } from '../util/helper'

const qwAppliance = axios.create({
  baseURL: APPLIANCE_BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default qwAppliance
