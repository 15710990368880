import axios from 'axios'
import Qs from 'qs'
import { QW_INBOX_API_URL } from '../config'
import { getAccessToken } from '../util/helper'

const qwInbox = axios.create({
  baseURL: QW_INBOX_API_URL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default qwInbox
