import _ from 'lodash'
import { bindActionCreators } from 'redux'
import * as appActions from '../../actions/index'

const bindDispatch = _.memoize(dispatch => ({
  dispatch,
  actions: bindActionCreators(appActions, dispatch)
}))

export default bindDispatch
