import update from 'react-addons-update'
import { I18n } from 'react-redux-i18n'

import qwInbox from '../common/client/qwInbox'
import { displayError } from './app'

const SET_USER_INFO = 'user/SET_USER_INFO'

function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    payload: userInfo
  }
}

export function fetchUserInfo() {
  return dispatch => {
    qwInbox
      .get(`/user-info`)
      .then(response => {
        dispatch(setUserInfo(response.data))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchUser')))
        dispatch(setUserInfo({}))
      })
  }
}

export default function reducer(
  state = {
    info: null
  },
  action
) {
  switch (action.type) {
    case SET_USER_INFO:
      return update(state, { info: { $set: action.payload } })
    default:
      return state
  }
}
