import update from 'react-addons-update'
import { I18n } from 'react-redux-i18n'

import qwTheatreACL from '../common/client/qwTheatreAccessControlList'
import { displayError } from './app'
import { getComapnyId } from '../common/util/helper'

const initialState = {
  users: [],
  offset: 0,
  ps: 10,
  loading: false,
  hasMore: false,
  companyName: ''
}

const SET_ALL_USERS = 'users/SET_ALL_USERS'
const SET_LOADING = 'users/SET_LOADING'
const SET_HAS_MORE = 'users/SET_HAS_MORE'
const SET_OFFSET = 'users/SET_OFFSET'
const SET_COMPANY_NAME = 'users/SET_COMPANY_NAME'

function setUsers(users) {
  return {
    type: SET_ALL_USERS,
    payload: users
  }
}

function setHasMore(hasMore) {
  return {
    type: SET_HAS_MORE,
    payload: hasMore
  }
}

function setLoading(hasMore) {
  return {
    type: SET_LOADING,
    payload: hasMore
  }
}

function setOffset(offset) {
  return {
    type: SET_OFFSET,
    payload: offset
  }
}

function setCompanyName(company) {
  return {
    type: SET_COMPANY_NAME,
    payload: company
  }
}

export function fetchUsers(offset = 0) {
  return dispatch => {
    dispatch(setLoading(true))
    qwTheatreACL
      .get(`/v1/users?ps=${initialState.ps}&offset=${offset}`)
      .then(response => {
        dispatch(setUsers(response.data.users))
        dispatch(setHasMore(response.data.hasMore))
        dispatch(setOffset(offset))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchAllUsers')))
        dispatch(setUsers({}))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export function fetchUsersForAdmin(offset = 0) {
  return dispatch => {
    dispatch(setLoading(true))
    qwTheatreACL
      .get(`/v1/admin/company/${getComapnyId()}/users?ps=${initialState.ps}&offset=${offset}`)
      .then(response => {
        dispatch(setUsers(response.data.users))
        dispatch(setCompanyName(response.data.displayName))
        dispatch(setHasMore(response.data.hasMore))
        dispatch(setOffset(offset))
      })
      .catch(() => {
        dispatch(displayError(I18n.t('errors.fetchAllUsers')))
        dispatch(setUsers({}))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_USERS:
      return update(state, { users: { $set: action.payload } })
    case SET_LOADING:
      return update(state, { loading: { $set: action.payload } })
    case SET_HAS_MORE:
      return update(state, { hasMore: { $set: action.payload } })
    case SET_OFFSET:
      return update(state, { offset: { $set: action.payload } })
    case SET_COMPANY_NAME:
      return update(state, { companyName: { $set: action.payload } })
    default:
      return state
  }
}
