import update from 'react-addons-update'

import { fetchCompositions } from './compositions'
//* EVENTS */
const TOGGLE_THEATRE_FILTER = 'compositionFilters/TOGGLE_THEATRE_FILTER'

//* Actions *//
export function toggleTheatreFilter(theatreInfo) {
  return dispatch => {
    dispatch({
      type: TOGGLE_THEATRE_FILTER,
      payload: theatreInfo
    })
    dispatch(fetchCompositions(true))
  }
}

//* Reducers *//
export default function reducer(
  state = {
    theatres: []
  },
  action
) {
  switch (action.type) {
    case TOGGLE_THEATRE_FILTER: {
      const theatreIndex = state.theatres.findIndex(t => action.payload.id === t.id)
      if (theatreIndex === -1) {
        return update(state, { theatres: { $push: [action.payload] } })
      }
      return update(state, { theatres: { $splice: [[theatreIndex, 1]] } })
    }

    default:
      return state
  }
}
