export const DCPStatuses = {
  dispatched: 'dispatched',
  executing: 'executing',
  queued: 'queued',
  failed: 'failed',
  error: 'error',
  succeeded: 'succeeded',
  cancelled: 'cancelled',
  scheduled: 'scheduled',
  downloading: 'downloading',
  downloaded: 'downloaded',
  expired: 'expired',
  pending: 'pending',
  delivered: 'delivered',
  pending_cancellation: 'pending_cancellation',
  unarchiving: 'unarchiving',
  downloading_unarchiving: 'downloading_unarchiving',
  pending_fulfilment_unarchiving: 'pending_fulfilment_unarchiving',
  archived: 'archived',
  downloading_archived: 'downloading_archived',
  pending_fulfilment_archived: 'pending_fulfilment_archived',
  stalled: 'stalled',
  downloading_stalled: 'downloading_stalled',
  pending_fulfilment_stalled: 'pending_fulfilment_stalled'
}

export const ShipmentStatuses = {
  Pending: 'Pending',
  InfoReceived: 'InfoReceived',
  InTransit: 'InTransit',
  OutForDelivery: 'OutForDelivery',
  AttemptFail: 'AttemptFail',
  Delivered: 'Delivered',
  Exception: 'Exception',
  Expired: 'Expired'
}

export const DCPDeliveryType = {
  THEATRE_APPLIANCE: 'THEATRE_APPLIANCE',
  PARTNERS: 'partners',
  ELECTRONIC: 'ELECTRONIC'
}

export const KDMStatuses = {
  success: 'success',
  emailed: 'emailed',
  inprogress: 'inprogress',
  failed: 'failed',
  generated: 'generated',
  unavailable: 'unavailable',
  inProgress: 'inProgress'
}

export const CPLAttributes = {
  type: 1,
  resolution: 2,
  stereoType: 3,
  aspectRatio: 4,
  audioFormats: 5,
  language: 6,
  subtitleLanguage: 7,
  closedCaptions: 8,
  ratings: 9
}

export const WebSocketSubscriptions = {
  SYSTEM_STATE: 'subscribe_systemstate_events',
  APPLIANCE_STATUS: 'subscribe_appliancestatus_changed',
  DOWNLOADS: 'subscribe_download_events'
}

export const WebSocketMessageTypes = {
  SYSTEM_STATE: 'systemstate_changed',
  APPLIANCE_STATUS: 'appliancestatus_changed',
  DOWNLOADS: 'downloads_progressed'
}

export const Enviroinment = {
  test: 'test'
}

export const FilterTypes = {
  THEATRES: 'Theatres',
  KDM_VALIDITY: 'KDM Validity',
  KDM_VALIDITY_OPTIONS: {
    VALID_NOW: 'validNow',
    FUTURE: 'future',
    EXPIRING: 'expiring',
    EXPIRED: 'expired'
  }
}
