import axios from 'axios'
import update from 'react-addons-update'
import { I18n } from 'react-redux-i18n'
import cinemasSearch from '../common/client/cinemasSearch'
import { displayError } from './app'
import { frameTheatreAddress } from '../common/util/helper'
import { fetchCompositions } from './compositions'

const { CancelToken } = axios
let source = CancelToken.source()

const RECEIVED_RECENT_THEATRES = 'theatreFilter/RECEIVED_RECENT_THEATRES'
const RECEIVED_SEARCHED_THEATRES = 'theatreFilter/RECEIVED_SEARCHED_THEATRES'
const UPDATE_SELECTED_THEATRE_DATA = 'theatreFilter/UPDATE_SELECTED_THEATRE_DATA'
const RESET_THEATRE_FILTER_DATA = 'theatreFilter/RESET_THEATRE_FILTER_DATA'
const UPDATE_SELECTED_LIST_FROM_SEARCH_THEATRE =
  'theatreFilter/UPDATE_SELECTED_LIST_FROM_SEARCH_THEATRE'
const TOGGLE_FETCH_IN_PROGESSS = 'theatreFilter/TOGGLE_FETCH_IN_PROGESSS'
const TOGGLE_SEARCH_IN_PROGESSS = 'theatreFilter/TOGGLE_SEARCH_IN_PROGESSS'

const initialState = {
  selectedList: [],
  selectedListFromSearch: [],
  searchList: [],
  recentList: [],
  fetchInProgress: false,
  searchInProgress: false,
  recentTheatresLimit: 10,
  theatreSearchLimit: 5
}

function receivedRecentTheatres(payload) {
  return { type: RECEIVED_RECENT_THEATRES, payload }
}

export function receivedSearchedTheatres(payload) {
  return { type: RECEIVED_SEARCHED_THEATRES, payload }
}

function toggleFetchInProgess(payload) {
  return {
    type: TOGGLE_FETCH_IN_PROGESSS,
    payload
  }
}

function toggleSearchInProgess(payload) {
  return {
    type: TOGGLE_SEARCH_IN_PROGESSS,
    payload
  }
}

function formatTheatreData(data) {
  return data.map(theatre => ({
    key: theatre.uuid,
    label: theatre.name,
    subLabel: frameTheatreAddress(theatre),
    type: I18n.t('filterPanel.theatres.type')
  }))
}

export function updateSelectedTheatre(payload) {
  return { type: UPDATE_SELECTED_THEATRE_DATA, payload }
}

export function resetCplFilterData() {
  return { type: RESET_THEATRE_FILTER_DATA }
}

export function updateSelectedListFromTheatreSearch(payload) {
  return { type: UPDATE_SELECTED_LIST_FROM_SEARCH_THEATRE, payload }
}

export function removeItemFromTheatreFilter(theatreItem) {
  return (dispatch, getState) => {
    dispatch(
      updateSelectedTheatre(
        getState().theatreFilter.selectedList.filter(theatre => theatre.key !== theatreItem.key)
      )
    )
    dispatch(
      fetchCompositions(
        true,
        0,
        getState().kdmValidityFilter.selectedList.concat(getState().theatreFilter.selectedList)
      )
    )
  }
}

export function getRecentTheatres() {
  return (dispatch, getState) => {
    dispatch(toggleFetchInProgess(true))
    const {
      theatreFilter: { recentTheatresLimit }
    } = getState()
    let url = `/facilities/search?ps=${recentTheatresLimit}&offset=0`

    return cinemasSearch
      .get(url, { cancelToken: source.token })
      .then(result => {
        dispatch(toggleFetchInProgess(false))
        dispatch(receivedRecentTheatres(formatTheatreData(result.data.facilities)))
      })
      .catch(error => {
        dispatch(toggleFetchInProgess(false))
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.fetchTheatres')))
        }
      })
  }
}

export function getTheatresSearch(query) {
  return (dispatch, getState) => {
    const theatreSearchLimit = getState().theatreFilter.theatreSearchLimit

    dispatch(receivedSearchedTheatres([]))
    dispatch(toggleSearchInProgess(true))
    return cinemasSearch
      .get(`/facilities/search?q=${query}&ps=${theatreSearchLimit}&offset=0&user=true`, {
        cancelToken: source.token
      })
      .then(result => {
        dispatch(toggleSearchInProgess(false))
        dispatch(receivedSearchedTheatres(formatTheatreData(result.data.facilities)))
      })
      .catch(error => {
        dispatch(toggleSearchInProgess(false))
        if (!axios.isCancel(error)) {
          dispatch(displayError(I18n.t('errors.fetchTheatres')))
        }
      })
  }
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case RECEIVED_RECENT_THEATRES:
      return update(state, { recentList: { $set: payload } })
    case RECEIVED_SEARCHED_THEATRES:
      return update(state, { searchList: { $set: payload } })
    case UPDATE_SELECTED_THEATRE_DATA:
      return update(state, { selectedList: { $set: payload } })
    case TOGGLE_FETCH_IN_PROGESSS:
      return update(state, { fetchInProgress: { $set: payload } })
    case TOGGLE_SEARCH_IN_PROGESSS:
      return update(state, { searchInProgress: { $set: payload } })
    case UPDATE_SELECTED_LIST_FROM_SEARCH_THEATRE:
      return update(state, { selectedListFromSearch: { $set: payload } })
    case RESET_THEATRE_FILTER_DATA:
      return initialState
    default:
      return state
  }
}
