import axios from 'axios'
import Qs from 'qs'
import { CINEMA_UI_URL } from '../config'
import { getAccessToken } from '../util/helper'

const cinemasSearch = axios.create({
  baseURL: `${CINEMA_UI_URL}/v3`,

  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getAccessToken()}`
  },
  paramsSerializer(params) {
    return Qs.stringify(params, { arrayFormat: 'brackets' })
  }
})

export default cinemasSearch
