import React from 'react'
import moment from 'moment'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="text-center">
        Copyright {moment().year()} Qube Cinema Inc. | <a
          href="http://www.qubecinema.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-link"
        >
          About
        </a> | <a href={`${process.env.REACT_APP_CINEMA_UI_URL}/terms_of_use`} target="_blank" rel="noopener noreferrer" className="footer-link">
          Terms of Use
        </a>
      </div>
    </footer>
  )
}

export default Footer
